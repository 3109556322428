import React from "react"
import "./styles.css"
import TsarkaFlag from "../../../images/tsarka-flag.png"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

const FaqSection = ({ setIsModalOpen }) => {
  return (
    <section className="web-faq-section container">
      <div className="row web-faq-section__content">
        <div className="col-4 col-md-12">
          {/*<h3 className="wt-text wt-text--500 wt-margin-bottom-10">*/}
          {/*  Какова цель курса?*/}
          {/*</h3>*/}
          {/*<p>*/}
          {/*  Научить разработчиков приложений выявлять уязвимости, оценивать их*/}
          {/*  риск для проекта и устранять их различными способами.*/}
          {/*</p>*/}
          <h3 className="wt-text wt-text--500 wt-margin-bottom-10">
            Какой необходим уровень подготовки?
          </h3>
          <p>
            Требуется понимание основ ИТ и ИБ, а также основ программирования.
          </p>
          <h3 className="wt-text wt-text--500 wt-margin-bottom-10">
            Требования к оснащению:
          </h3>
          <p className="web-faq-section__small-text">
            Можно использовать:
            <ul className="web-faq-section__list">
              <li>Kali Live USB.</li>
              <li>Kali как основная система.</li>
              <li>
                Windows + VirtualBox/VMware + Kali установленная на виртуальную
                машину.
              </li>
              <li>Любой удобный дистрибутив linux.</li>
            </ul>
            <p>
              Минимально приемлемые требования к ПК.
              <br />
              Процессор: 4 ядра.
              <br />
              Озу: 8 гигабайт.
              <br />
              Жесткий диск: 250 гигабайт.
            </p>
          </p>
        </div>

        <div className="col-8 col-md-12">
          <div className="web-faq-section__request">
            <div className="web-faq-section__info">
              <p className="wt-text wt-text--red wt-text--400 wt-margin-bottom-15">
                Онлайн курс
              </p>
              <h3 className="wt-text wt-text--500 wt-margin-bottom-15">
                Практические
                <br />
                основы пентеста
              </h3>
              <p className="wt-text wt-text--200 web-faq-section__text">
                Форма обучения: онлайн курс.
                <br />
                Периодичность проведения: 2 занятия в неделю
                <br />
                Продолжительность: 38 академ. часов.
              </p>
              <button
                className="web-faq-section__btn wt-button"
                onClick={() => scrollTo("#web-price")}
              >
                Забронировать место
              </button>
            </div>
            <img
              src={TsarkaFlag}
              alt="Flag"
              className="web-faq-section__flag"
              width="350px"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqSection
