import React, { useState } from "react"
import "./styles.css"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import Spinner from "../../images/spinner.svg"

const RequestForm = ({ price, setIsModalOpen, course }) => {
  const { handleSubmit, register, errors } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = values => {
    setLoading(true)
    let url =
      "https://api.hsforms.com/submissions/v3/integration/submit/8196302/5303006d-a502-4a5c-9714-03e157297883"
    let param = {
      fields: [
        {
          name: "course",
          value: values.course,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "firstname",
          value: values.firstname,
        },
        {
          name: "lastname",
          value: values.lastname,
        },
        {
          name: "work",
          value: values.work,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "city",
          value: values.city,
        },
        {
          name: "comment",
          value: values.comment,
        },
        {
          name: "price",
          value: price || "",
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: "TSARKA Academy | ЦАРКА Академия",
      },
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    }).then(result => {
      if (result.ok) {
        toast("Ваш запрос был успешно выполнен")
        setIsModalOpen(null)
      } else {
        setLoading(false)
        toast("Что-то пошло не так. Пожалуйста, повторите попытку позже")
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="request-form">
      {loading && (
        <div className={"wt-spinner"}>
          <img src={Spinner} alt="spinner" />
        </div>
      )}
      <h5 className="wt-text wt-text--red wt-text--450">Подача заявки</h5>
      <select
        className="wt-select"
        name="course"
        defaultValue={course}
        ref={register({
          required: "Required",
        })}
      >
        <option value="Практические основы пентеста">
          Практические основы пентеста
        </option>
        <option value="Система управления информационной безопасностью. ISO/IEC 27001">
          Система управления информационной безопасностью. ISO/IEC 27001
        </option>
        <option value="Безопасность АСУ ТП">Безопасность АСУ ТП</option>
      </select>
      <input
        className="wt-input"
        type="text"
        placeholder="Имя*"
        name="firstname"
        ref={register({
          required: "Required",
        })}
      />
      <input
        className="wt-input"
        type="text"
        placeholder="Фамилия*"
        name="lastname"
        ref={register({
          required: "Required",
        })}
      />
      <input
        className="wt-input"
        type="email"
        name="email"
        placeholder="Электронная почта*"
        ref={register({
          required: "Required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address",
          },
        })}
      />
      <input
        className="wt-input"
        type="text"
        name="work"
        placeholder="Род деятельности"
        ref={register}
      />
      <input
        className="wt-input"
        type="text"
        name="phone"
        placeholder="Контактный телефон"
        ref={register}
      />
      <input
        className="wt-input"
        type="text"
        name="city"
        placeholder="Город"
        ref={register}
      />
      <input
        className="wt-input"
        type="text"
        name="comment"
        placeholder="Комментарий"
        ref={register}
      />
      <button className="wt-button" type="submit">
        Забронировать место
      </button>
      <div className="request-form__contract">
        <a
          target="_blank"
          className="wt-text wt-text--red wt-text--bold"
          href="https://cert.kz/wp-content/uploads/2022/04/contract_3.docx"
        >
          Договор
        </a>{" "}
        публичной оферты
      </div>
    </form>
  )
}

export default RequestForm
