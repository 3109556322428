import React from 'react'
import BgArt from '../../../images/bg-art.png'
import './styles.css'

const WhoSection = () => {
  return (
    <section className="asu-who-section container">
      <div className="row">
        <div className="col-4 col-md-12">
          <div className="asu-who-section__info">
            <div className="asu-who-section__info-title">
              Какова цель курса?
            </div>
            <div className="asu-who-section__info-text">
              Увеличить осведомленность об угрозах и методах защиты критической инфраструктуры.
            </div>
          </div>
        </div>
        <div className="col-8 col-md-12">
          <div className="asu-who-section__info">
            <img className='asu-who-section__art' src={BgArt} alt="art" />
            <div className="asu-who-section__info-title">
              Для кого этот курс?
            </div>
            <div className="asu-who-section__info-text">
              Курс предназначен для сотрудников подразделений безопасности, руководителей и специалистов подразделений, отвечающих за обеспечение безопасности и техническую поддержку АСУ ТП.
              Для выпускников\студентов последних курсов (IT/ИБ/АСУ), технический и IT персонал предприятий.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoSection
