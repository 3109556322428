import React from "react"
import "./styles.css"
import ArtImage from "../../../images/bg-art.png"

const ProgramSection = () => {
  return (
    <section id="program" className="asu-program-section container">
      <div className="iso-program-section__head">
        <h2 className="wt-text wt-text--500 wt-margin-bottom-0">
          ПРОГРАММА КУРСА
        </h2>
        <div className="asu-program-section__pdf">
          <div className="wt-text wt-text--200 wt-text--red">СКАЧАТЬ PDF</div>
          <div>
            <a
              target="_blank"
              href="https://cert.kz/wp-content/uploads/2022/04/KP_asu_3.pdf"
              className="wt-text wt-text--300"
            >
              КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ
            </a>
            <span className="asu-program-section__space">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <a
              target="_blank"
              href="https://cert.kz/wp-content/uploads/2022/04/asu-program_2.pdf"
              className="wt-text wt-text--300"
            >
              ПРОГРАММА
            </a>
          </div>
        </div>
      </div>

      <div className="asu-program-section__modules">
        <div className="asu-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 1</span>
            <span className="wt-text wt-text--300">
              Архитектура и текущие угрозы
            </span>
          </div>
          <span className="asu-program-section__time wt-text wt-text--red">
            1 час
          </span>
        </div>

        <div className="asu-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 2</span>
            <span className="wt-text wt-text--300">
              Стандарты и рекомендации
            </span>
          </div>
          <span className="asu-program-section__time wt-text wt-text--red">
            1 час
          </span>
        </div>

        <div className="asu-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 3</span>
            <span className="wt-text wt-text--300">Практика: примеры атак</span>
          </div>
          <span className="asu-program-section__time wt-text wt-text--red">
            1 час
          </span>
        </div>

        <div className="asu-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 4</span>
            <span className="wt-text wt-text--300">Оценка риска и аудит</span>
          </div>
          <span className="asu-program-section__time wt-text wt-text--red">
            1 час
          </span>
        </div>

        <div className="asu-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 5</span>
            <span className="wt-text wt-text--300">Методы защиты</span>
          </div>
          <span className="asu-program-section__time wt-text wt-text--red">
            1 час
          </span>
        </div>

        <div className="asu-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 6</span>
            <span className="wt-text wt-text--300">
              Практика: применение защитных инструментов
            </span>
          </div>
          <span className="asu-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>
      </div>

      <img src={ArtImage} alt="art" className="asu-program-section__art" />
    </section>
  )
}

export default ProgramSection
