import React from "react"
import './styles.css'
import ArtImage from '../../../images/art.png'
import ArtMobileImage from '../../../images/art-mobile.png'
import BayanImage from '../../../images/bayan.png'

const TrainerSection = () => {
  return (
    <section id='trainer' className='iso-trainer-section container'>
      <div className="card">
        <img src={ArtImage} alt="" className='iso-trainer-section__art'/>
        <img src={ArtMobileImage} alt="" className='iso-trainer-section__art-mobile'/>

        <h2 className='wt-text wt-text--600'>Главный тренер</h2>
        <p className='wt-text wt-text--300 iso-trainer-section__text'>
          Баян Оразгалиева – аудитор информационной безопасности TSARKA,
          обладатель сертификата BSI (The British Standards Institution)
          <span className='wt-text wt-text--red'> ведущего аудитора ISO 27001:2013. </span>
          Имеет опыт в проведении аудитов информационной безопасности, оказания
          консалтинговых услуг в крупных государственных и частных компаниях.
        </p>

        <p>
          <a className='wt-text wt-text--red' href="mailto:bo@cybersec.kz">bo@cybersec.kz</a>
        </p>

        <img src={BayanImage} alt="" className='iso-trainer-section__image'/>
      </div>
    </section>
  )
}

export default TrainerSection
