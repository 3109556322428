import React from 'react'
import './styles.css'
import scrollTo from "gatsby-plugin-smoothscroll"

const ValueSection = () => {
  return (
    <section id='value' className='iso-value-section container'>
      <div className='card'>
        <div className='iso-value-section__content'>
          <div>
            <h2 className='wt-text wt-text--600'>
              О курсе
            </h2>
            <p className='wt-text wt-text--300'>
              Стандарт <span className='wt-text wt-text--red'>ISO 27001:2013 </span>
              является обобщением мирового опыта в управлении информационной
              безопасностью и определяет общий подход в управлении, оценки риска
              и эффективности организации в контексте информационной безопасности.
            </p>
          </div>
          <div className='wt-padding-left-75'>
            <h2 className='wt-text wt-text--600'>
              В чем <span className='wt-text wt-text--red'>ценность</span> курса?
            </h2>
            <p className='wt-text wt-text--300'>
              В ходе курса слушатели знакомятся не только
              с теоретическими материалами, но и с практическими способами реализации требований, устанавливаемые стандартом.
            </p>
            <p className='wt-text wt-text--300'>
              Освоив курс, вы вносите серьезную
              <span className='wt-text wt-text--red'> инвестицию в развитие себя как профессионала.</span>
            </p>
            <button
              onClick={() => scrollTo('#trainer')}
              className='wt-button wt-button--large wt-width-1-1'>
              Профиль эксперта
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValueSection
