// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asu-js": () => import("./../src/pages/asu.js" /* webpackChunkName: "component---src-pages-asu-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iso-js": () => import("./../src/pages/iso.js" /* webpackChunkName: "component---src-pages-iso-js" */),
  "component---src-pages-pentest-js": () => import("./../src/pages/pentest.js" /* webpackChunkName: "component---src-pages-pentest-js" */)
}

