import React, { useState } from "react"
import "./styles.css"
import Helm from "../../../images/helm.png"
import TsarkaAcademy from "../../../images/web-price__tsarka-academy.svg"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import Spinner from "../../../images/spinner.svg"

const PriceSection = () => {
  return (
    <section id="web-price">
      <div className="web-price__wrapper">
        <div className="container web-price__container">
          <div className="web-price__content">
            <h2>
              Запишитесь на курс или
              <br />
              получите консультацию
            </h2>
            <span>Скидка закончится 15 мая</span>
            <p>
              Цена при подачи заявки
              <br />
              до 15 мая 2022 года
            </p>
            <div className="web-price__content__price">
              <p className="web-price__content__price--old">600 000 KZT</p>
              <p className="web-price__content__price--new">500 000 KZT</p>
            </div>
          </div>
          <div className="web-price__content">
            <Application mobile />
            <img className="web-price__helm" src={Helm} alt="" />
            <img className="web-price__svg-text" src={TsarkaAcademy} alt="" />
          </div>
        </div>
      </div>
      <Application />
    </section>
  )
}

const Application = ({ mobile }) => {
  const { handleSubmit, register, errors } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = values => {
    setLoading(true)
    let url =
      "https://api.hsforms.com/submissions/v3/integration/submit/8196302/5303006d-a502-4a5c-9714-03e157297883"
    let param = {
      fields: [
        {
          name: "course",
          value: "Практические основы пентеста",
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "firstname",
          value: values.firstname,
        },
        {
          name: "lastname",
          value: values.lastname,
        },
        {
          name: "work",
          value: values.work,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "city",
          value: values.city,
        },
        {
          name: "comment",
          value: values.comment,
        },
        {
          name: "price",
          value: "500 000 KZT",
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: "TSARKA Academy | ЦАРКА Академия",
      },
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then(result => {
        if (result.ok) {
          toast("Ваш запрос был успешно выполнен")
        } else {
          setLoading(false)
          toast("Что-то пошло не так. Пожалуйста, повторите попытку позже")
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <div
      className={
        "web-price__form-application " + (mobile ? "mobile" : "desktop")
      }
    >
      <h4>Подача заявки</h4>
      <h5>Начало курса: 24 мая</h5>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="web-price__form"
        name="request-form"
      >
        {loading && (
          <div className={"wt-spinner"}>
            <img src={Spinner} alt="spinner" />
          </div>
        )}
        <input
          className="wt-input"
          type="text"
          placeholder="Имя*"
          name="firstname"
          ref={register({
            required: "Required",
          })}
        />
        <input
          className="wt-input"
          type="text"
          placeholder="Фамилия*"
          name="lastname"
          ref={register({
            required: "Required",
          })}
        />
        <input
          className="wt-input"
          type="email"
          name="email"
          placeholder="Электронная почта*"
          ref={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
        />
        <input
          className="wt-input"
          type="text"
          name="work"
          placeholder="Род деятельности"
          ref={register}
        />
        <input
          className="wt-input"
          type="text"
          name="phone"
          placeholder="Контактный телефон"
          ref={register}
        />
        <input
          className="wt-input"
          type="text"
          name="city"
          placeholder="Город"
          ref={register}
        />
        <input
          className="wt-input"
          type="text"
          name="comment"
          placeholder="Комментарий"
          ref={register}
        />
        <button className="wt-button" type="submit">
          Забронировать место
        </button>
      </form>
      <p>
        <a
          target="_blank"
          className="wt-text wt-text--red wt-text--bold"
          href="https://cert.kz/wp-content/uploads/2022/04/contract_3.docx"
        >
          Договор
        </a>{" "}
        публичной оферты
      </p>
    </div>
  )
}

export default PriceSection
