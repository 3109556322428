import React from "react"
import Layout from "../components/layout"
import Header from "../sections/Header"
import FlagSection from "../sections/index/Flag"
import Footer from "../sections/Footer"
import MissionSection from "../sections/web/Mission"
import AboutSection from "../sections/index/About"
import CoursesSection from "../sections/index/Courses"
import scrollTo from "gatsby-plugin-smoothscroll"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="TSARKA Академия" />
      <Header
        menuItems={[
          {
            id: "https://school.cert.kz/",
            text: "Школа",
            target: "_blank",
          },
          {
            id: "#academy",
            text: "Академия",
          },
          {
            id: "#mission",
            text: "Миссия",
          },
          {
            id: "#about",
            text: "О нас",
          },
          {
            id: "#footer",
            text: "Контакты",
          },
        ]}
      >
        <button
          onClick={() => scrollTo("#courses")}
          className="wt-button wt-button--small wt-button--no-upper wt-margin-left-15"
        >
          Курсы
        </button>
      </Header>

      <FlagSection />
      <MissionSection />
      {/*<MissionSection />*/}
      <CoursesSection />
      <AboutSection />

      <Footer />
    </Layout>
  )
}

export default IndexPage
