import React from "react"
import "./styles.css"
import scrollTo from "gatsby-plugin-smoothscroll"

const ValueSection = () => {
  return (
    <section className="web-value-section container">
      <div className="card">
        <h2 className="wt-text wt-text--600">
          В чем <span className="wt-text wt-text--red">ценность</span> курса?
        </h2>
        <div className="web-value-section__content">
          <div>
            <p className="wt-text wt-text--300">
              Помимо авторских лекций, учащиеся смогут сразу применять
              полученные знания и практиковаться в ходе обучения. Курс построен
              по методу «минимум теории, больше практики». Более 60% времени на
              тренинге «Практические основы пентеста» уделяется практике.
            </p>
            <p className="wt-text wt-text--300">
              Прямая обратная связь сразу от четырех тренеров – экспертов ИБ по
              каждому направлению курса.
            </p>
            <p className="wt-text wt-text--300">
              Освоив курс, вы вносите серьезную{" "}
              <span className="wt-text wt-text--red">
                инвестицию в развитие себя как профессионала.
              </span>
            </p>
          </div>
          <div className="wt-padding-left-75">
            <p className="wt-text wt-text--300">
              • Авторы курса – 4 лучших пентестера в ЦАРКА дают реальные
              практические навыки по выявлению уязвимостей, делятся актуальными
              способами их устранения, а также best practices.
              <br />
              • Быстро и четко разбирают все этапы выявления уязвимостей белым и
              черным ящиком.
              <br />• Эти навыки применяют наши специалисты в своей ежедневной
              деятельности, теперь эти же навыки могут быть доступны и Вам.
            </p>
            <button
              onClick={() => scrollTo("#trainer")}
              className="wt-button wt-button--large wt-width-1-1"
            >
              Профиль экспертов
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValueSection
