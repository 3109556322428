import React from "react"
import "./styles.css"

const ResultSection = () => {
  return (
    <section className="web-result-section container">
      <h2 className="wt-text wt-text--500">Что получаете в итоге?</h2>
      <div className="row">
        <div className="col-6 col-md-12">
          <p className="wt-text wt-text--400 wt-margin-bottom-15">
            По итогам курса учащиеся овладеют навыками:
          </p>
          <p className="wt-text wt-text--400">
            • использования средств анализа защищенности кода;
            <br />
            • внедрения практики безопасной разработки (Secure SDLC);
            <br />
            • выявления уязвимостей белым и черным ящиком;
            <br />• эксплуатирования уязвимостей OWASP Top-10.
          </p>
        </div>
        <div className="col-6 col-md-12">
          <p className="wt-text wt-text--400 wt-margin-bottom-15">
            Особый бонус:
          </p>
          <p className="wt-text wt-text--400">
            По результатам обучения в финальных проектах учащиеся проведут
            самостоятельный аудит.
          </p>
        </div>
      </div>
    </section>
  )
}

export default ResultSection
