import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../sections/Header"
import Main from "../sections/iso/Main"
import ValueSection from "../sections/iso/Value"
import WhoSection from "../sections/iso/Who"
import FlagSection from "../sections/iso/Flag"
import DocumentSection from "../sections/iso/Document"
import ResultSection from "../sections/iso/Result"
import PricingSection from "../sections/iso/Pricing"
import TrainerSection from "../sections/iso/Trainer"
import ProgramSection from "../sections/iso/Program"
import ReviewsSection from "../sections/iso/Reviews"
import Footer from "../sections/Footer"
import ReactModal from "react-modal"
import RequestForm from "../components/RequestForm"
import { customStyles } from "./pentest"

const IsoPage = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout>
      <SEO title="Система управления информационной безопасностью. ISO/IEC 27001" />
      <Header
        setIsModalOpen={setIsModalOpen}
        menuItems={[
          {
            id: "#value",
            text: "О курсе",
          },
          {
            id: "#price",
            text: "Стоимость",
          },
          {
            id: "#trainer",
            text: "Тренер",
          },
          {
            id: "#program",
            text: "Программа",
          },
          {
            id: "#reviews",
            text: "Отзывы",
          },
        ]}
      >
        <button
          onClick={() => setIsModalOpen(true)}
          className="wt-button wt-button--small wt-button--no-upper wt-margin-left-15"
        >
          Забронировать место
        </button>
      </Header>

      <Main />
      <ValueSection />
      <WhoSection />
      <FlagSection setIsModalOpen={setIsModalOpen} />
      <DocumentSection />
      <ResultSection />
      <PricingSection setIsModalOpen={setIsModalOpen} />
      <TrainerSection />
      <ProgramSection />
      <ReviewsSection />

      <Footer />

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <RequestForm
          price={modalIsOpen}
          setIsModalOpen={setIsModalOpen}
          course={
            "Система управления информационной безопасностью. ISO/IEC 27001"
          }
        />
      </ReactModal>
    </Layout>
  )
}

export default IsoPage
