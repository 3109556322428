import React from "react"
import './styles.css'
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Sergey from '../../../images/sergey.png'
import Vova from '../../../images/vova.png'
import Nurshat from '../../../images/nurshat.png'
import Zhandos from '../../../images/zhandos.png'
import Ivan from '../../../images/ivan.png'
import Katya from '../../../images/katya.png'
import Lev from '../../../images/lev.png'
import { useWindowSize } from "../../../hooks/useWindowSize"

SwiperCore.use([Navigation]);

const ReviewsSection = () => {
  const windowSize = useWindowSize()

  return (
    <section id='reviews' className='iso-reviews-section container'>
      <h2 className='wt-text wt-text--red wt-text--600 iso-reviews-section__title'>Отзывы</h2>

      <Swiper
        spaceBetween={30}
        slidesPerView={windowSize.width > 992 ? 2 : 1}
        navigation
      >
        <SwiperSlide>
          <ReviewCard
            text={`
              “После данного курса наконец то пришло понимание что такое iso27001. Преподаватель объясняет все четко
               раскладывая информацию  по "Полочкам" :) Интересно выполнять задания, не стоит их откладывать на потом, 
               сразу после лекции лучше сделать, так лучше закрепится материал. В целом классный курс.” 
            `}
            name='Иван Волынкин'
            status='Эксперт информационной безопасности'
          >
            <img src={Ivan} alt=""/>
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              “Курс хорошо структурирован. Благодаря навыкам преподавателя объяснять простыми словами сложные вещи, я
              смог разобраться в структуре стандарта, его требованиях.
              Особо был полезен разбор контролей из Приложения А. Полученные навыки пригодятся при проведении аудитов 
              информационной безопасности.” 
            `}
            name='Лев Шмелев'
            status='Эксперт информационной безопасности'
          >
            <img src={Lev} alt=""/>
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              “Выражаю искреннюю благодарность за проведение учебного курса по стандарту ISO 27001!\n
              
              По итогу учебного курса я получил понимание того, что информационная безопасность в компании – это не 
              только техническая сторона вопроса, но также и вопросы, связанные с управлением рисками. Очень важно четко 
              идентифицировать активы, угрозы, оценивать и обрабатывать риски.” 
            `}
            name='Жандос Боранбай'
            status='Эксперт информационной безопасности'
          >
            <img src={Zhandos} alt=""/>
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              "Курс хорошо структурирован. Сложные и важные аспекты изложены доступно, а также подкреплены интересными 
              практическими примерами от отличного специалиста. После курса остались положительные впечатления."
            `}
            name='Екатерина Шевченко'
            status='Эксперт информационной безопасности'
          >
            <img src={Katya} alt=""/>
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              "Хороший курс, понятный, много примеров с раскрытием сути. 
              Преподаватель отличный, старался донести до всех, отвечая 
              на все вопросы.
              Стала понятна картина оценки рисков 
              и составления документов."
            `}
            name='Сергей Власов'
            status='Web разработчик'
          >
            <img src={Sergey} alt=""/>
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              “Данный курс определенно не оставит никого равнодушным. После каждой лекции проводились мини-тестирования,
              приводились примеры и аналогии реальных кейсов, рассматривались практические аспекты внедрения требований 
              стандарта.  
              Считаю, что курс будет полезен абсолютно всем, в особенности тем, кто отвечает за обеспечение
              информационной безопасности в компании”
            `}
            name='Владимир Ким'
            status='Backend разработчик'
          >
            <img src={Vova} alt=""/>
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              “Курс предоставляет компактную информацию без лишней воды. Закрепляются ключевые моменты с помощью тестов 
              после каждой лекции. Профессионализм лектора обеспечил интерес, а так же легкость в усвоении материала. 
              Спасибо за работу!” 
            `}
            name='Нуршат Базилов'
            status='Backend разработчик'
          >
            <img src={Nurshat} alt=""/>
          </ReviewCard>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

const ReviewCard = ({ children, text, name, status }) => {
  return (
    <div className='iso-reviews-section__card card'>
      {children}
      <p className='wt-text wt-text--400 wt-text--center wt-text--italic wt-margin-bottom-45'>
        {text}
      </p>
      <span className='wt-text wt-text--red wt-text--200'>{name}</span>
      <span className='wt-text wt-text--gray wt-text--100 wt-text--center'>{status}</span>
    </div>
  )
}

export default ReviewsSection
