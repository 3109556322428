import React from "react"
import "./styles.css"
import ArtImage from "../../../images/art.png"
import Ivan from "../../../images/ivan-2.png"
import Oleg from "../../../images/oleg.png"
import Eldos from "../../../images/eldos.png"
import Danil from "../../../images/danil.png"

const TrainerSection = () => {
  const mainTrainer = {
    name: "Иван Волынкин",
    description:
      "Security Expert, TeamLead команды Red Team в ЦАРКА, обладатель сертификата OSCP (Offensive Security Certified Professional), спикер KazHackStan, участник PHDays Standoff.",
    img: Ivan,
    color: "#FF3B3B",
  }
  const trainerList = [
    {
      name: "Олег Ивахнов",
      description:
        "Security Expert, Senior Penetration Tester команды Red Team в ЦАРКА, обладатель сертификатов OSWP (Offensive Security Wireless Professinal) и OSCP (Offensive Security Certified Professional), спикер KazHackStan, участник PHDays Standoff.",
      img: Oleg,
      color: "#535353",
    },
    {
      name: "Елдос Нурекен",
      description:
        "Security Expert, Senior Penetration Tester команды Red Team в ЦАРКА, обладатель сертификата OSWE (Offensive Security Web Expert), спикер KazHackStan, участник PHDays Standoff.",
      img: Eldos,
      color: "#000000",
    },
    {
      name: "Данил Турсумбаев",
      description:
        "Security Expert, Senior Penetration Tester команды Red Team в ЦАРКА, обладатель сертификатов OSCP (Offensive Security Certified Professional) и OSWE (Offensive Security Web Expert), спикер KazHackStan, участник PHDays Standoff.",
      img: Danil,
      color: "#FF3B3B",
    },
  ]
  return (
    <section id="trainer" className="web-trainer-section container">
      <h2>Тренера курса</h2>
      <TrainerCard trainer={mainTrainer} />
      <div className="web-trainer__list">
        <TrainerCardSm className="main-trainer" trainer={mainTrainer} />
        {trainerList.map((trainer, index) => (
          <TrainerCardSm key={index} trainer={trainer} />
        ))}
      </div>
    </section>
  )
}

const TrainerCard = ({ trainer }) => {
  return (
    <div className="web-trainer__card">
      <img src={ArtImage} alt="" className="web-trainer-section__art" />
      <div className="web-trainer__card__content">
        <h3>{trainer.name}</h3>
        <p>{trainer.description}</p>
      </div>
      <div className="web-trainer__card__content">
        <img src={trainer.img} alt="" />
      </div>
    </div>
  )
}

const TrainerCardSm = ({ trainer, className }) => {
  return (
    <div className={"web-trainer__card--sm " + className}>
      <Art2Image color={trainer.color} />
      <img
        src={trainer.img}
        alt=""
        className="web-trainer-section__trainer-img"
      />
      <div className="web-trainer__card--sm__content">
        <h3>{trainer.name}</h3>
        <p>{trainer.description}</p>
      </div>
    </div>
  )
}

const Art2Image = ({ color }) => {
  return (
    <svg
      width="350"
      height="204"
      className="web-trainer-section__art-2"
      viewBox="0 0 350 204"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        d="M-192.494 182.309H820.004L820.792 122.142C816.189 111.639 806.696 104.087 795.428 101.963L772.065 97.5594C727.653 89.189 684.428 75.433 643.347 56.596C618.117 45.0267 588.218 53.846 573.306 77.2566L559.319 99.2134C539.588 130.189 502.271 145.093 466.63 136.233L424.726 125.817C402.046 120.179 378.342 120.088 355.619 125.552L340.355 129.223C278.831 144.017 213.915 130.236 163.708 91.7219L133.008 68.1721C74.8567 27.8247 0.0977478 38.15 -65.6769 64.2888C-75.1596 68.0573 -83.8962 70.3248 -91.0862 70.2379C-141.528 69.6288 -192.494 92.4455 -192.494 92.4455V182.309Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M820.792 182.309H-191.705L-192.494 122.142C-187.891 111.639 -178.398 104.087 -167.129 101.963L-143.766 97.5595C-99.3541 89.189 -56.1292 75.433 -15.0485 56.596C10.1823 45.0267 40.0806 53.846 54.9932 77.2566L68.9797 99.2134C88.7111 130.189 126.027 145.093 161.669 136.233L203.573 125.817C226.253 120.179 249.957 120.088 272.68 125.552L328.704 139.024C364.447 147.619 402.16 139.613 431.328 117.238L449.944 102.958C477.437 81.8672 514.874 79.2518 545.033 96.3142C570.387 110.658 601.193 111.092 627.813 99.2635C658.074 85.817 697.274 70.505 719.385 70.238C769.827 69.6289 820.792 92.4456 820.792 92.4456V182.309Z"
        fill="currentColor"
      />
      <path
        d="M-192.494 204H820.004L820.793 143.833L815.14 130.934C814.018 128.375 812.347 126.094 810.245 124.252L788.084 104.839C737.3 60.3497 663.191 54.8727 606.418 91.4127L572.26 111.735C530.981 136.292 480.467 139.696 436.266 120.899C404.751 107.496 369.609 105.23 336.633 114.475L280.718 130.152C250.002 138.763 217.143 135.13 189.051 120.015L183.199 116.867C151.713 99.927 113.973 99.2865 81.9305 115.149C46.423 132.726 4.58229 129.104 -30.9107 111.498C-51.8872 101.092 -74.6443 92.1277 -91.0862 91.9291C-141.528 91.32 -192.494 114.137 -192.494 114.137V204Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TrainerSection
