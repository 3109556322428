import React from "react"
import "./styles.css"
import Logo from "../../../images/logo.png"
import { Link } from "gatsby"

const CoursesSection = () => {
  return (
    <section id="courses" className="courses-section container">
      <h2 className="wt-text wt-text--600">Открытые курсы</h2>
      <div className="courses-section__cards">
        <Card
          date="Набор открыт"
          title={
            <h3 className="wt-text wt-text--550 wt-margin-bottom-10">
              Практические
              <br />
              основы пентеста
            </h3>
          }
          info={
            <p>
              Форма обучения: онлайн курс.
              <br />
              Время проведения курса: 2 месяца.
              <br />
              Продолжительность: 38 академ. часов.
            </p>
          }
          to="/pentest"
        />
        <Card
          date="Набор открыт"
          title={
            <h3 className="wt-text wt-text--550 wt-margin-bottom-10">
              Система управления
              <br /> информационной безопасностью.
              <br />
              ISO/IEC 27001
            </h3>
          }
          info={
            <p>
              Форма обучения: онлайн курс. <br />
              Время проведения курса: 5 дней. <br />
              Продолжительность: 12 академ. часов.
            </p>
          }
          to="/iso"
        />
        <Card
          date="Набор открыт"
          title={
            <h3 className="wt-text wt-text--550 wt-margin-bottom-10">
              Безопасность АСУ ТП
            </h3>
          }
          info={
            <p>
              Форма обучения: онлайн курс.
              <br />
              Время проведения курса: 3 дня.
              <br />
              Продолжительность: 7 академ. часов.
            </p>
          }
          to="/asu"
        />
        <Card
          date="Набор открыт"
          title={
            <h3 className="wt-text wt-text--550 wt-margin-bottom-10">
              Курсы для детей и новичков в TSARKA Cyber School
            </h3>
          }
          to="https://school.cert.kz/"
        />
      </div>
    </section>
  )
}

const Card = ({ date, title, info, to = "/", inactive = false }) => {
  return (
    <div
      className={
        (inactive ? "courses-section__card--inactive " : "") +
        "courses-section__card"
      }
    >
      <img src={Logo} alt="Tsarka logo" />
      <h5 className="wt-text wt-text--red wt-text--450 wt-margin-bottom-20">
        {date}
      </h5>
      {title}
      {info}
      <Link to={to}>
        <button className="wt-button wt-button--wide">узнать подробнее</button>
      </Link>
    </div>
  )
}

export default CoursesSection
