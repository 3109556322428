import React from "react"
import './styles.css'
import CardImage from '../../../images/card1.png'

const DocumentSection = () => {
  return (
    <section className='iso-document-section container'>
      <div className='card'>
        <img src={CardImage} alt="card" className='iso-document-section__card1'/>
        <img src={CardImage} alt="card" className='iso-document-section__card2'/>
        <img src={CardImage} alt="card" className='iso-document-section__card3'/>
        <div className='iso-document-section__content'>
          <h2 className='wt-text wt-text--600'>Документ об окончании курса:</h2>
          <p>
            Сертификат о прохождении курса <br/>
            «Система управления информационной безопасностью. ISO/IEC 27001»,
          </p>
        </div>
      </div>
    </section>
  )
}

export default DocumentSection
