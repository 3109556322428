import React from "react"
import "./styles.css"

const Information = () => {
  return (
    <section className="wrapper-for-section">
      <div className="container wrapper-content">
        <p>
          <span>Когда</span>
          <span>24 мая 2022</span>
        </p>
        <p>
          <span>Уровень</span>
          <span>Базовый</span>
        </p>
        <p>
          <span>Формат обучения</span>
          <span>Онлайн</span>
        </p>
        <p>
          <span>Документ</span>
          <span>Сертификат о прохождении курса</span>
        </p>
      </div>
    </section>
  )
}

export default Information
