import { useEffect, useState } from "react"

export const useWindowPosition = () => {
  const isClient = typeof window === "object"
  const [scrollTop, setScrollTop] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const setThrottleScrollTop = throttle(setScrollTop,200)
  const setThrottleScrolled = throttle(setScrolled,200)

  useEffect(() => {
    if (!isClient) {
      return false
    }
    let prevScrollpos = window.pageYOffset;

    const handleResize = () => {
      let scroll = document.documentElement.scrollTop || document.body.scrollTop
      setThrottleScrollTop(scroll)

      let currentScrollPos = window.pageYOffset;

      if (currentScrollPos > prevScrollpos && currentScrollPos > 100) {
        setThrottleScrolled(true)
      } else {
        setThrottleScrolled(false)
      }
      prevScrollpos = currentScrollPos;
    }

    handleResize()
    window.addEventListener("scroll", handleResize)

    return () =>
      window.removeEventListener("scroll", handleResize)
  }, [])

  return { scrollTop, scrolled}
}

const throttle = (func, ms) => {

  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper(callback) {

    if (isThrottled) { // (2)
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function() {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}
