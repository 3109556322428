import React, { useState } from "react"
import "./styles.css"
import BurgerImage from "../../images/burger.svg"
import CloseImage from "../../images/close.svg"
import ArrowImage from "../../images/arrow.svg"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useWindowPosition } from "../../hooks/useWindowPosition"
import classNames from "classnames"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"
import { Link } from "gatsby"

const Header = ({ menuItems = [], children }) => {
  const [menu, setMenu] = useState(false)
  const { scrollTop, scrolled } = useWindowPosition()

  return (
    <>
      <header
        id="header"
        className={classNames(
          "header",
          { "header--fixed": scrollTop > 100 },
          { "header--hide": scrolled }
        )}
      >
        <div className="header__block">
          <div className="container">
            <p className="header__text">Контактный номер</p>
            <div className="header__phone">
              <a href="tel:+7-700-550-1060">+7-700-550-1060</a>
            </div>
          </div>
        </div>
        <div className="container">
          <Link to="/">
            <div className="wt-text wt-text--450 wt-margin-right-25 header__logo">
              <div>ЦАРКА</div>
              <div className="wt-text wt-text--red">Академия</div>
            </div>
          </Link>
          <Tippy
            trigger="mouseenter"
            interactive={true}
            content={
              <div className="header__projects-tooltip">
                <a
                  href="https://www.cybersec.kz/ru"
                  target="_blank"
                  rel="noreferrer"
                >
                  Официальный сайт
                </a>
                <a href="https://cert.kz/" target="_blank" rel="noreferrer">
                  CERT.KZ
                </a>
                <a href="https://wtotem.com/" target="_blank" rel="noreferrer">
                  WebTotem
                </a>
                <a
                  href="https://school.cert.kz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Школа
                </a>
              </div>
            }
          >
            <div
              id="projects"
              className="wt-text wt-text--300 header__projects"
            >
              Проекты
              <img src={ArrowImage} alt="arrow" width={10} height={10} />
            </div>
          </Tippy>

          <ul className="header__menu">
            {/*Сори за говнокод но хули сделаешь */}
            {menuItems.map(item =>
              item.target ? (
                <li>
                  <a target={item.target} href={item.id}>
                    {item.text}
                  </a>
                </li>
              ) : (
                <li onClick={() => scrollTo(item.id)}>{item.text}</li>
              )
            )}
          </ul>
          {children}
          {!menu ? (
            <img
              src={BurgerImage}
              alt="menu"
              className="header__burger"
              width={20}
              onClick={() => setMenu(true)}
            />
          ) : (
            <img
              src={CloseImage}
              alt="menu"
              className="header__burger"
              width={20}
              onClick={() => setMenu(false)}
            />
          )}
        </div>
      </header>
      {menu && (
        <div className="header__mobile-menu">
          {menuItems.map(item => (
            <p
              onClick={() => {
                setMenu(false)
                scrollTo(item.id)
              }}
            >
              {item.text}
            </p>
          ))}
        </div>
      )}
    </>
  )
}

export default Header
