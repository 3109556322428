import React from "react"
import './styles.css'
import ConsoleImage from '../../../images/console.png'
import BgArt from '../../../images/bg-art.png'
import BgArtMobile from '../../../images/bg-art-mobile.png'
import Logo from '../../../images/logo1.png'
import scrollTo from "gatsby-plugin-smoothscroll"

const Main = () => {
  return (
    <section id='academy' className='asu-main-section container'>
      <img className='asu-main-section__art' src={BgArt} alt="art" />
      <img className='asu-main-section__art-mobile' src={BgArtMobile} alt="art" />
      <div className='asu-main-section__content'>
        <h5 className="wt-text wt-text--450 wt-text--red wt-text--upper">Набор открыт</h5>
        <h1 className='wt-text wt-text--700'>
          <img src={Logo} alt="logo" className='asu-main-section__logo' />
          <span>Безопасность АСУ ТП</span>
        </h1>
        <button className='wt-button wt-button--small z-index-10' onClick={() => scrollTo('#price')}>Узнать стоимость</button>
      </div>
      <div className='asu-main-section__image'>
        <img src={ConsoleImage} />
      </div>
    </section>
  )
}

export default Main
