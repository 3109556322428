import React from "react"
import './styles.css'
import TsarkaFlag from '../../../images/tsarka-flag.png'
import scrollTo from "gatsby-plugin-smoothscroll"

const FlagSection = () => {
  return (
    <section id='academy' className='flag-section container'>
      <div className="row">
        <div className="col-12">
          <div className="flag-section__request">
            <div className="flag-section__info">
              <h3 className="wt-text wt-text--600 wt-margin-bottom-15">
                Обучайтесь у<br/> лидера рынка
              </h3>
              <p className="wt-text wt-text--200 flag-section__text">
                ЦАРКА Академия- это созданный при ЦАРКА учебный центр, осуществляющий подготовку и повышение
                квалификации специалистов по различным направлениям информационной безопасности.
              </p>
              <button className="flag-section__btn wt-button wt-width-1-1"
                      onClick={() => {
                        scrollTo('#courses')
                      }}>
                Посмотреть курсы
              </button>
            </div>
            <img src={TsarkaFlag} alt="Flag" className="flag-section__flag" width="350px" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FlagSection
