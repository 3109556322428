import React from "react"
import './styles.css'
import BgArt from '../../../images/bg-art.png'
import BgArtMobile from '../../../images/bg-art-mobile.png'
import UmbrellaImage from '../../../images/umbrella.png'
import Logo from '../../../images/logo1.png'
import scrollTo from "gatsby-plugin-smoothscroll"

const Main = () => {
  return (
    <section id='academy' className='iso-main-section container'>
      <img className='iso-main-section__art' src={BgArt} alt="art"/>
      <img className='iso-main-section__art-mobile' src={BgArtMobile} alt="art"/>
      <div className='iso-main-section__content'>
        <h5 className="wt-text wt-text--450 wt-text--red wt-text--upper">Набор открыт</h5>
        <h1 className='wt-text wt-text--700'>
          <img src={Logo} alt="logo" className='iso-main-section__logo'/>
          <span>
            Система управления информационной безопасностью.
            ISO/IEC 27001
          </span>
        </h1>
        <button className='wt-button wt-button--small'  onClick={() => scrollTo('#price')}>Узнать стоимость</button>
      </div>
      <div className='iso-main-section__image'>
        <img src={UmbrellaImage} alt=""/>
      </div>
    </section>
  )
}

export default Main
