import React, { useState } from "react"
import "./styles.css"
import ArrayImage from "../../../images/arrow.svg"
import ArtImage from "../../../images/bg-art.png"

const ProgramSection = () => {
  const [more, setMore] = useState(false)
  return (
    <section id="program" className="web-program-section container">
      <div className="web-program-section__head">
        <h2 className="wt-text wt-text--500 wt-margin-bottom-0">
          ПРОГРАММА КУРСА:
          <span className="wt-text wt-text--red">
            {" "}
            Практические основы пентеста
          </span>
        </h2>
        <div className="web-program-section__pdf">
          <div className="wt-text wt-text--200 wt-text--red">СКАЧАТЬ PDF</div>
          <div>
            <a
              target="_blank"
              href="https://cert.kz/wp-content/uploads/2022/04/KP_pentest_2.pdf"
              className="wt-text wt-text--300"
            >
              КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ
            </a>
            <span className="web-program-section__space">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <a
              target="_blank"
              href="https://cert.kz/wp-content/uploads/2022/04/KP_program_2.pdf"
              className="wt-text wt-text--300"
            >
              ПРОГРАММА
            </a>
          </div>
        </div>
      </div>

      <div className="web-program-section__modules">
        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--450 wt-text--red">
              Вводная часть
            </span>
          </div>
          <span className="web-program-section__time wt-text wt-text--red">
            1 занятие, 2 часа
          </span>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 1</span>
            <span className="wt-text wt-text--300">
              Знакомство со структурой курса и используемым программным
              обеспечением.
            </span>
          </div>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 2</span>
            <span className="wt-text wt-text--300">
              Классификация OWASP top 10.
            </span>
          </div>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 3</span>
            <span className="wt-text wt-text--300">
              Методология, методы, виды и инструментарий.
            </span>
          </div>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--450 wt-text--red">Часть 1</span>
            <span className="wt-text wt-text--450">
              «Безопасность веб-приложений»
            </span>
          </div>
          <span className="web-program-section__time wt-text wt-text--red">
            10 часов
          </span>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 1</span>
            <span className="wt-text wt-text--300">
              Разведка и сбор информации.
            </span>
          </div>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 2</span>
            <span className="wt-text wt-text--300">
              Фаззинг веб-приложений.
            </span>
          </div>
        </div>

        <div className="web-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 3</span>
            <span className="wt-text wt-text--300">
              ClientSide: Open Redirect, CSRF, HTML Injection, Cross-Site
              Scripting.
            </span>
          </div>
        </div>

        {more && (
          <>
            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 4
                </span>
                <span className="wt-text wt-text--300">
                  ServerSide: HTTP Parameter Pollution, ServerSide Request
                  Forgery.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 5
                </span>
                <span className="wt-text wt-text--300">
                  ServerSide: SQL Injection.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 6
                </span>
                <span className="wt-text wt-text--300">
                  ServerSide: RCE, LFI, RFI.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 7
                </span>
                <span className="wt-text wt-text--300">
                  ServerSide: Deserialization, Race condition
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 8
                </span>
                <span className="wt-text wt-text--300">
                  ServerSide: XXE, SSTI.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 9
                </span>
                <span className="wt-text wt-text--300">
                  ServerSide: IDOR, Accout Tackeover.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--450 wt-text--red">
                  Часть 2.
                </span>
                <span className="wt-text wt-text--450">
                  «Пост эксплуатация и повышение привилегий».
                </span>
              </div>
              <span className="web-program-section__time wt-text wt-text--red">
                6-8 часов
              </span>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 1
                </span>
                <span className="wt-text wt-text--300">
                  Пост эксплуатация и повышение привелегий в Windows OS.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 2
                </span>
                <span className="wt-text wt-text--300">
                  Пост эксплуатация и повышение привелегий в Linux OS.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--450 wt-text--red">
                  Часть 3.
                </span>
                <span className="wt-text wt-text--450">
                  «Аудит беспроводных сетей - Wi-Fi»
                </span>
              </div>
              <span className="web-program-section__time wt-text wt-text--red">
                6-8 часов
              </span>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 1
                </span>
                <span className="wt-text wt-text--300">
                  Стандарты.Устройства. Подготовка рабочей среды.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 2
                </span>
                <span className="wt-text wt-text--300">
                  Обзор базового инструментария. Разведка. Атаки на WEP.
                </span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 3
                </span>
                <span className="wt-text wt-text--300">Атаки на WPA/WPA2.</span>
              </div>
            </div>

            <div className="web-program-section__module web-program-section__module--hide">
              <div className="wt-flex-column">
                <span className="wt-text wt-text--300 wt-text--red">
                  Модуль 4
                </span>
                <span className="wt-text wt-text--300">
                  Автоматизированные иснтрументы аудита Wi-Fi.
                </span>
              </div>
            </div>
          </>
        )}
      </div>

      {!more && (
        <div className="web-program-section__more">
          <div
            className="wt-flex-column wt-flex-center wt-flex-middle"
            onClick={() => setMore(true)}
          >
            <span className="wt-text wt-text--400 wt-text--center wt-margin-bottom-15">
              Загрузить все
            </span>
            <img src={ArrayImage} alt="" width={40} />
          </div>
        </div>
      )}

      <img src={ArtImage} alt="art" className="web-program-section__art" />
    </section>
  )
}

export default ProgramSection
