import React from 'react'
import './styles.css'
import scrollTo from "gatsby-plugin-smoothscroll"

const ValueSection = () => {
  return (
    <section id='value' className='asu-value-section container'>
      <div className='card'>
        <div className='asu-value-section__content'>
          <div>
            <h2 className='wt-text wt-text--600'>
              О курсе
            </h2>
            <p className='wt-text wt-text--300'>
              Первый, не имеющий аналогов в Казахстане, авторский курс по кибербезопасности
              АСУ ТП. Курс знакомит слушателей с современным подходом
              в обеспечении <span className='wt-text wt-text--red'>безопасной эксплуатации</span> автоматизированной системы управления технологическим процессом (АСУ ТП) на предприятии.
            </p>
            <p className='wt-text wt-text--300'>
              В ходе курса слушатели знакомятся с аспектами информационной безопасности АСУ ТП для поддержания необходимого уровня безопасности и непрерывности производства.
            </p>
          </div>
          <div className='wt-padding-left-75'>
            <h2 className='wt-text wt-text--600'>
              В чем <span className='wt-text wt-text--red'>ценность</span> курса?
            </h2>
            <p className='wt-text wt-text--300'>
              Кибербезопасность АСУ и критической инфраструктуры становится ведущим мировым трендом. Многие компании включают защиту АСУ ТП
              в стратегию управления. Вместе с этим наблюдается дефицит кадров, имеющих представление об угрозах и методах защиты данных систем. Курс предоставляет устойчивую базу для дальнейшего развития специалистов в данной отрасли
            </p>
            <button
              onClick={() => scrollTo('#trainer')}
              className='wt-button wt-button--large'>
              Профиль эксперта
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValueSection
