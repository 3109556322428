import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../sections/Header"
import Footer from "../sections/Footer"
import ReactModal from "react-modal"
import { customStyles } from "./pentest"
import RequestForm from "../components/RequestForm"
import "../sections/faq/srtles.css"
import Image from "../../static/faq/faq_heading.png"
import BgArt from "../images/bg-art.png"

const FaqPage = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout>
      <SEO title="Система управления информационной безопасностью. ISO/IEC 27001" />
      <Header
        setIsModalOpen={setIsModalOpen}
        menuItems={[
          {
            id: "#value",
            text: "О курсе",
          },
          {
            id: "#price",
            text: "Стоимость",
          },
          {
            id: "#trainer",
            text: "Тренер",
          },
          {
            id: "#program",
            text: "Программа",
          },
          {
            id: "#reviews",
            text: "Отзывы",
          },
        ]}
      >
        <button
          onClick={() => setIsModalOpen(true)}
          className="wt-button wt-button--small wt-button--no-upper wt-margin-left-15"
        >
          Забронировать место
        </button>
      </Header>

      <div className="art-container art-1">
        <img src={BgArt} alt="" className="faq__bg art-1" />
      </div>

      <div className="art-container art-2">
        <img src={BgArt} alt="" className="faq__bg art-2" />
      </div>

      <div className="art-container art-3">
        <img src={BgArt} alt="" className="faq__bg art-3" />
      </div>

      <Heading />
      <Faq />

      <Footer />

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <RequestForm
          price={modalIsOpen}
          setIsModalOpen={setIsModalOpen}
          course={
            "Система управления информационной безопасностью. ISO/IEC 27001"
          }
        />
      </ReactModal>
    </Layout>
  )
}

export default FaqPage

const Heading = () => {
  return (
    <section className="faq-section heading">
      <div className="text">
        <h1>Часто задаваемые вопросы</h1>
        <p>
          Если в данном разделе вы не нашли ответ на интересующий вас вопрос,
          задайте его оператору информационной поддержки.
        </p>
      </div>
      <img src={Image} alt="" className="image" />
    </section>
  )
}

const questions = [
  {
    id: 1,
    title:
      "1. Я новичок, у меня нет опыта в ИТ, на какие курсы мне лучше записаться? ",
    description:
      "Для новичков мы предлагаем базовый курс по информационной безопасности в TSARKA Сyber School.\n" +
      "Также для вас будет полезен курс «Система управления информационной безопасностью. ISO 27001».",
  },
  {
    id: 2,
    title: "2. Вы выдаете сертификат по завершению обучения?\n",
    description:
      "Да, все студенты, прошедшие курс и выполнившие практические задания получают наши сертификаты установленного образца. \n" +
      "Сертификат может быть двух образцов , первый , подтверждающий, что прослушали курс, второй при успешной сдачи экзаменационного задания.",
  },
  {
    id: 3,
    title: "3. В каком формате проходят занятия (онлайн/оффлайн) ?",
    description:
      "Большинство наших курсов проходит онлайн. Занятие для детей проводится в оффлайн формате.\n",
  },
  {
    id: 4,
    title: "4. У вас есть обучение для детей/ подростков/ школьников?",
    description:
      "Да, у нас доступен курс по основам кибербезопасности в TSARKA Cyber School для детей от 13 до 18 лет.",
  },
  {
    id: 5,
    title: "5. Кто ваши Преподаватели?",
    description:
      "Наши преподаватели опытные сертифицированные специалисты в различных областях информационной безопасности, сотрудники TSARKA и имеют опыт работы в крупных зарубежных компаниях.",
  },
  {
    id: 6,
    title: "6. Какова стоимость курсов, есть ли скидки, возможность рассрочки?",
    description:
      "Стоимость курсов указана на сайте. Скидки зависят от предварительной регистрации на курс и от количества участников от организации. В отдельных случаях есть возможность рассмотрения рассрочки.",
  },
  {
    id: 7,
    title:
      "7. После прохождения курсов в TSARKA Academy есть ли возможность дальнейшего трудоустройства в TSARKA? ",
    description:
      "Наши выпускники имеют возможность пройти стажировку и, при положительных результатах, работать в нашей компании или получить от нас рекомендацию для трудоустройства в компаниях партнеров.",
  },
]

const Faq = () => {
  return (
    <section className="faq-section ">
      <div className="questions">
        {questions.map(e => (
          <article className="question" id={e.id}>
            <h2>{e.title}</h2>
            <p>{e.description}</p>
          </article>
        ))}
      </div>
    </section>
  )
}
