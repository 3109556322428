import React from "react"
import "./styles.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BgArt from "../../../images/bg-art.png"
import BgArtEllipse from "../../../images/bg-black-ellipse.png"
import scrollTo from "gatsby-plugin-smoothscroll"

const Main = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "four-person-web-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="container">
      <img
        className="web-main-section-art__ellipse"
        src={BgArtEllipse}
        alt="art"
      />

      <section id="academy" className="web-main-section">
        <>
          <img className="web-main-section__art" src={BgArt} alt="art" />
          <div className="web-main-section__content">
            <h5 className="wt-text wt-text--450 wt-text--red wt-text--upper">
              Начало курса: 24 мая 2022 года
            </h5>
            <h1 className="wt-text wt-text--700">
              <span>Практические основы пентеста</span>
            </h1>
          </div>
        </>
        <button
          onClick={() => scrollTo("#priceDep")}
          className="wt-button wt-button--small web-main-section__moreBtn"
        >
          Узнать стоимость
        </button>
        <div className="web-main-section__image">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </div>
      </section>
    </div>
  )
}

export default Main
