import React from "react"
import './styles.css'

const ResultSection = () => {
  return (
    <section className='iso-result-section container'>
      <h2 className='wt-text wt-text--500'>Что получаете в итоге?</h2>
      <div className="row">
        <div className="col-12">
          <p className='wt-text wt-text--400 wt-margin-bottom-15'>
            По итогам курса учащиеся овладеют навыками:
          </p>
          <p className='wt-text wt-text--400'>
            • Ориентироваться в ключевых аспектах обеспечения информационной безопасности на предприятии, основных угрозах и уязвимостях.<br/>
            • Понимать требования стандарта ISO 27001:2013.<br/>
            • Грамотно подходить к разработке и внедрению у себя в организации системы управления информационной безопасностью.<br/>
            • Грамотно подходить к выбору необходимых методов и средств защиты информации.
          </p>
          <p className='wt-text wt-text--400'>
            Дополнительная информация:
          </p>
          <p className='wt-text wt-text--400'>
            В ходе курса по завершению каждого модуля учащимся будет предложен тест для проверки усвоенного материала.
          </p>
        </div>
      </div>
    </section>
  )
}

export default ResultSection
