import React from "react"
import "./styles.css"
import BgArt from "../../../images/bg-art.png"

const PricingSection = ({ setIsModalOpen }) => {
  return (
    <section id="price" className="iso-pricing-section container">
      <img className="iso-pricing-section__art" src={BgArt} alt="art" />
      <h2 className="wt-text wt-text--600">
        <span className="wt-text wt-text--red">Стоимость</span> курса
      </h2>
      <div className="iso-pricing-section__card">
        <div>
          <span className="iso-pricing-section__price wt-margin-bottom-5">
            <span className="wt-text wt-text--red">190 000</span> KZT /
            {" "}
            <span className="wt-text wt-text--opacity wt-text--400 wt-text--line-through">
              300 000
            </span>
          </span>
          <span className="wt-text wt-text--opacity">
            Цена за одного участника
          </span>
        </div>
        <div>
          <button className="wt-button" onClick={() => setIsModalOpen(true)}>
            Забронировать место
          </button>
        </div>
      </div>
    </section>
  )
}

export default PricingSection
