import React from "react"
import "./styles.css"
import scrollTo from "gatsby-plugin-smoothscroll"

const PricinngDep = () => {
  const currency = "kzt"

  return (
    <section id="priceDep" className="web-price-section container">
      <div className="web-price-section__wrap">
        <h2 className="wt-text wt-text--600">
          <span className="wt-text wt-text--red">Стоимость</span> курса
        </h2>
      </div>
      <div className="row">
        <div className="col-4 col-md-12">
          <div className="card">
            <span className="web-price-section__type">
              За <span className="wt-text wt-text--red">одного</span> слушателя
            </span>
            <span className="web-price-section__old-price">
              {currency === "kzt" ? "600 000 KZT" : "104 000 РУБ"}
            </span>
            <span className="web-price-section__price">
              {currency === "kzt" ? "500 000 KZT" : "52 000 РУБ"}
            </span>
            <span className="web-price-section__hint">
              Цена при подачи заявки до 15 мая 2022 года
            </span>
            <button
              className="wt-button wt-width-1-1"
              onClick={() => scrollTo("#web-price")}
            >
              Забронировать место
            </button>
          </div>
        </div>
        <div className="col-4 col-md-12">
          <div className="card card--border">
            <span className="web-price-section__type">
              При наборе группы от 2 до 5 человек от организации
            </span>
            <span className="web-price-section__old-price">
              {currency === "kzt" ? "576 000 KZT" : "86 000 РУБ"}
            </span>
            <span className="web-price-section__price">
              {currency === "kzt" ? "480 000 KZT" : "43 000 РУБ"}
            </span>
            <span className="web-price-section__hint">
              Цена при подачи заявки до 15 мая 2022 года
            </span>
            <button
              className="wt-button wt-width-1-1"
              onClick={() => scrollTo("#web-price")}
            >
              Забронировать место
            </button>
          </div>
        </div>
        <div className="col-4 col-md-12">
          <div className="card">
            <span className="web-price-section__type">
              При наборе группы выше 5 человек от организации
            </span>
            <span className="web-price-section__old-price">
              {currency === "kzt" ? "540 000 KZT" : "70 000 РУБ"}
            </span>
            <span className="web-price-section__price">
              {currency === "kzt" ? "450 000 KZT" : "35 000 РУБ"}
            </span>
            <span className="web-price-section__hint">
              Цена при подачи заявки до 15 мая 2022 года
            </span>
            <button
              className="wt-button wt-width-1-1"
              onClick={() => scrollTo("#web-price")}
            >
              Забронировать место
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricinngDep
