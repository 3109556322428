import React from "react"
import "./styles.css"
import ArtImage from "../../../images/art.png"
import ArtMobileImage from "../../../images/art-mobile.png"

const TrainerSection = () => {
  return (
    <section id="trainer" className="asu-trainer-section container">
      <div className="card">
        <img src={ArtImage} alt="" className="asu-trainer-section__art" />
        <img
          src={ArtMobileImage}
          alt=""
          className="asu-trainer-section__art-mobile"
        />

        <h2 className="wt-text wt-text--600">Главный тренер</h2>
        <p className="wt-text wt-text--300 asu-trainer-section__text">
          Виталий Перов – эксперт по аудиту промышленных IT систем, имеет опыт
          проектирования, разработки, эксплуатации. Обладатель сертификатов от
          ведущих производителей промышленных решений Emerson, Wonderware,
          Schneider Electric, Siemens. Участник международных соревнований The
          Standoff. Специалист по кибербезопасности ЦАРКА. Специализируется по
          аудиту систем на уязвимости. Имеет опыт работы в нефтегазовой и
          горно-обогатительной сфере.
        </p>
      </div>
    </section>
  )
}

export default TrainerSection
