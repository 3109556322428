import React from "react"
import './styles.css'
import CardImage from '../../../images/asu-card.png'

const DocumentSection = () => {
  return (
    <section className='asu-document-section container'>
      <div className='card'>
        <img src={CardImage} alt="card" className='asu-document-section__card1' />
        <img src={CardImage} alt="card" className='asu-document-section__card2' />
        <img src={CardImage} alt="card" className='asu-document-section__card3' />
        <div className='asu-document-section__content'>
          <h2 className='wt-text wt-text--600'>Документ об окончании курса:</h2>
          <p>
            Сертификат о прохождении курса <br />
            «Безопасность АСУ ТП».
          </p>
        </div>
      </div>
    </section>
  )
}

export default DocumentSection
