import React from "react"
import "./styles.css"

const WhoSection = () => {
  return (
    <section className="web-who-section container">
      <div className="row">
        <div className="col">
          <h2 className="web-who-section__title wt-text wt-text--600">
            Для кого этот курс?
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-4 col-md-12">
          <div className="web-who-section__info">
            <div className="web-who-section__info-title">Для разработчиков</div>
            <div className="web-who-section__info-text">
              Для разработчиков, которые хотят оптимизировать свои приложения.
              Курс поможет понять, как работают ОС и фреймворки изнутри. В
              результате вы будете создавать надежные грамотные решения,
              которыми не смогут воспользоваться злоумышленники.
            </div>
          </div>
        </div>
        <div className="col-4 col-md-12">
          <div className="web-who-section__info">
            <div className="web-who-section__info-title">
              Для администраторов
            </div>
            <div className="web-who-section__info-text">
              Для администраторов и devops-инженеров, перед которыми стоит
              задача настроить надежную, безопасную инфраструктуру. Курс усилит
              ваши компетенции умением выявлять уязвимости.
            </div>
          </div>
        </div>
        <div className="col-4 col-md-12">
          <div className="web-who-section__info">
            <div className="web-who-section__info-title">
              Для специалистов информационной безопасности
            </div>
            <div className="web-who-section__info-text">
              Для специалистов информационной безопасности и тех, кто хочет
              развиваться как профессиональный пентестер. Курс даст вам ключевые
              знания в области этичного хакинга и позволит освоить практические
              навыки Пентеста под менторством опытного специалиста.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoSection
