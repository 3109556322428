import React, { useState } from "react"
import "./styles.css"
import { useWindowSize } from "../../../hooks/useWindowSize"

const AboutSection = () => {
  const [more, setMore] = useState(false)
  const windowSize = useWindowSize()

  return (
    <section id="about" className="about-section container">
      <h2 className="wt-text wt-text--600">О ЦАРКА</h2>

      <p className="wt-text wt-text--300">
        ОЮЛ «Центр анализа и расследования кибер атак» (далее - ЦАРКА) - одна из
        ведущих организаций в области информационной безопасности в Центральной
        Азии. Организация была образована в 2015 году и за время своего
        существования завоевала признание специалистов по информационной
        безопасности по всему миру. Первый частный CERT в Казахстане.
      </p>

      {!more && (
        <span
          className="about-section__more wt-text wt-text--400 wt-text--upper wt-text--red wt-text--underline"
          onClick={() => setMore(!more)}
        >
          Читать полностью
        </span>
      )}

      {(windowSize.width > 540 || more) && (
        <div>
          <p className="wt-text wt-text--300">
            Организация предоставляет широкий спектр услуг в области оценки
            защищенности, в том числе проведение аудитов ИБ и тестирований на
            проникновение, анализ защищенности банковских систем и
            бизнес-приложений, веб-приложений, информационных инфраструктур.
          </p>
          <p className="wt-text wt-text--300">
            Более 70 экспертов. Эксперты ЦАРКА обладают сертификатами OSCP,
            OSWP, CHFI, CISA, CCNA Security, ISO/IEC 27001—2013, OSCE и CEH и
            регулярно принимают участие в международных конференциях, таких как
            PHDays, ZeroNights, Инфофорум, КодИБ.
          </p>
          <p className="wt-text wt-text--300">
            Эксперты организации являются авторами публикаций на таких ресурсах
            как журнал Хакер, HabraHabr, DigitalReport, ProfIT.kz и др.
          </p>
          <p className="wt-text wt-text--300">
            Команда ЦАРКА занимала 1 и 2 места в соревновании The Standoff на
            международной конференции по информационной безопасности
            PositiveHackDays с 2017 по 2019 года.
          </p>

          <a
            href="https://www.cybersec.kz/ru"
            target="_blank"
            rel="noreferrer"
            className="wt-button"
          >
            Сайт компании
          </a>
        </div>
      )}
    </section>
  )
}

export default AboutSection
