import React from "react"
import './styles.css'

const ResultSection = () => {
  return (
    <section className='asu-result-section container'>
      <h2 className='wt-text wt-text--500'>Что получаете в итоге?</h2>
      <div className="row">
        <div className="col-12">
          <p className='wt-text wt-text--400 wt-margin-bottom-15'>
            По итогам курса учащиеся овладеют навыками:
          </p>
          <p className='wt-text wt-text--400'>
            • Ориентироваться в ключевых аспектах обеспечения безопасности АСУ на предприятии, основных угрозах и уязвимостях.<br />
            • Грамотно подходить к выбору и использованию доступных на рынке продуктов и инструментов.<br />
            • Проектировать безопасную сетевую архитектуру АСУ и расставлять приоритеты для защиты.<br />
            • Базовые навыки аудита систем АСУ.<br />
            •	 Приобретенные знания будут полезны при прохождении собеседования на позиции в таких компаниях как ТШО\КПО\NCOC и пр.
          </p>
        </div>
      </div>
    </section>
  )
}

export default ResultSection

