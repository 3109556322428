import "./src/styles/global.css"
import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

export function wrapRootElement({ element }) {
  const flag = JSON.stringify({
    domain: "https://kazhackstan.kz",
    hint: "fnd.wrd.ctf",
    data: "Alli:x$2&:@.khs",
  })
  return (
    <div>
      {element}
      <ToastContainer />
      <pre style={{ position: "absolute", top: 0, left: 0, opacity: 0 }}>
        {flag}
      </pre>
    </div>
  )
}
