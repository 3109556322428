import React from "react"
import './styles.css'
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Arseni from '../../../images/arseni.png'
import Dastan from '../../../images/dastan.png'
import Lev from '../../../images/lev.png'
import Nurtas from '../../../images/nurtas.png'
import Alisher from '../../../images/alisher.png'
import { useWindowSize } from "../../../hooks/useWindowSize"

SwiperCore.use([Navigation]);

const ReviewsSection = () => {
  const windowSize = useWindowSize()

  return (
    <section id='reviews' className='asu-reviews-section container'>
      <h2 className='wt-text wt-text--red wt-text--600 asu-reviews-section__title'>Отзывы</h2>

      <Swiper
        spaceBetween={30}
        slidesPerView={windowSize.width > 992 ? 2 : 1}
        navigation
      >
        <SwiperSlide>
          <ReviewCard
            text={`
              “Курс насыщен полезной информацией, рассмотрели много практических 
              аспектов. Смело рекомендую техническим специалистам, желающим 
              развиваться в данной отрасли.” 
            `}
            name='Арсений Гриб'
            status='Специалист информационной безопасности'
          >
            <img src={Arseni} alt="" />
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              “Содержательный курс. Дает понимание основных принципов 
              обеспечения безопасности АСУ на предприятии, актуальных угроз и 
              уязвимостей.” 
            `}
            name='Лев Шмелев'
            status='Эксперт информационной безопасности'
          >
            <img src={Lev} alt="" />
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`
              “Уверен, что данный курс по ИБ АСУ ТП будет полезен не только начинающим но и опытным экспертам в области 
              информационной безопасности, курс помимо предоставления новых знаний позволяет правильно систематизировать 
              имеющиеся, а после наглядных практических примеров не остаётся  вопросов.” 
            `}
            name='Дастан Жаналиев'
            status='Руководитель технического отдела'
          >
            <img src={Dastan} alt="" />
          </ReviewCard>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

const ReviewCard = ({ children, text, name, status }) => {
  return (
    <div className='asu-reviews-section__card card'>
      {children}
      <p className='wt-text wt-text--400 wt-text--center wt-text--italic wt-margin-bottom-45'>
        {text}
      </p>
      <span className='wt-text wt-text--red wt-text--200'>{name}</span>
      <span className='wt-text wt-text--gray wt-text--100 wt-text--center'>{status}</span>
    </div>
  )
}

export default ReviewsSection
