import React from "react"
import './styles.css'
import TsarkaFlag from '../../../images/tsarka-flag.png'

const FlagSection = ({ setIsModalOpen }) => {
  return (
    <section className='iso-flag-section container'>
      <div className="row">
        <div className="col-12">
          <div className="iso-flag-section__request">
            <div className="iso-flag-section__info">
              <h5
                className="wt-text wt-text--red wt-text--500 wt-margin-bottom-15"
              >
                Набор открыт
              </h5>
              <h3 className="wt-text wt-text--600 wt-margin-bottom-15">
                Система управления<br/>
                информационной безопасностью.<br/>
                ISO/IEC 27001
              </h3>
              <p className="wt-text wt-text--200 iso-flag-section__text">
                Форма обучения: онлайн курс.<br/>
                Периодичность проведения курса: 5 дня.<br/>
                Старт курса: по мере формирования группы.<br/>
              </p>
              <button className="iso-flag-section__btn wt-button wt-width-1-1"
                      onClick={() => setIsModalOpen(true)}>
                Забронировать место
              </button>
            </div>
            <img src={TsarkaFlag} alt="Flag" className="iso-flag-section__flag" width="350px" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FlagSection
