import React from "react"
import "./styles.css"
import VisaMastercard from "../../images/visa-mastercard.png"
import Hoody from "../../images/hoody.png"

const Footer = () => {
  return (
    <footer id="footer" className="footer container">
      <div className="footer content__container">
        <div className="footer__content">
          <h2 className="wt-text wt-text--600">О нас</h2>
          <p>
            <a href="https://cybersec.kz/about-us">О компании</a>
            <br />
            <br />
            <a href="https://cybersec.kz/about-us/vacancies">Вакансии</a>
          </p>
          <br />
          <h2 className="wt-text wt-text--600">Ресурсы</h2>
          <p>
            <a href="/faq">FAQs \ Часто задаваемые вопросы</a>
            <br />
            <br />
            <a href="https://cert.kz/ru/">Блог</a>
          </p>
        </div>
        <div className="footer__content">
          <h2 className="wt-text wt-text--600">Контакты</h2>
          <h4 className="wt-text wt-text--450">
            По вопросам курсов и регистрации свяжитесь с TSARKA Academy
          </h4>
          <p>
            <a href="tel:+7-700-550-1060">+7-700-550-1060</a>
            <br />
            <a href="mailto:school@cert.kz">school@cert.kz</a>
          </p>
          <br />
          <h4 className="wt-text wt-text--450">
            По вопросам обучения детей и курсов для новичков свяжитесь с TSARKA
            Cyber School
          </h4>
          <p>
            <a href="tel:+7-700-550-1060">+7-700-550-1060</a>
            <br />
            <a href="mailto:school@cert.kz">school@cert.kz</a>
          </p>
        </div>
      </div>

      <div className="footer__bottom">
        <span className="wt-text wt-text--200">
          © TSARKA 2017-{new Date().getFullYear()}, All Rights Reserved
          {/*<div>*/}
          {/*  <a*/}
          {/*    target="_blank"*/}
          {/*    className="wt-text wt-text--red wt-text--bold"*/}
          {/*    href="https://cert.kz/wp-content/uploads/2022/04/contract_3.docx"*/}
          {/*  >*/}
          {/*    Договор*/}
          {/*  </a>{" "}*/}
          {/*  публичной оферты*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <a*/}
          {/*    download*/}
          {/*    className="wt-text wt-text--red wt-text--bold"*/}
          {/*    href="/QA.docx"*/}
          {/*  >*/}
          {/*    Q&A*/}
          {/*  </a>*/}
          {/*</div>*/}
        </span>
        <img src={VisaMastercard} alt="Visa Mastercard" />
      </div>

      <img src={Hoody} alt="Tsarka hoody" className="footer__image" />
    </footer>
  )
}

export default Footer
