import React from "react"
import "./styles.css"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Reviewer1 from "../../../images/lev-new.png"
import Reviewer2 from "../../../images/katya.png"
import Reviewer3 from "../../../images/vladislav.png"
import { useWindowSize } from "../../../hooks/useWindowSize"

SwiperCore.use([Navigation])

const ReviewsSection = () => {
  const windowSize = useWindowSize()

  return (
    <section className="web-reviews-section container">
      <h2 className="wt-text wt-text--red wt-text--600 web-reviews-section__title">
        Отзывы
      </h2>

      <Swiper
        spaceBetween={30}
        slidesPerView={windowSize.width > 992 ? 2 : 1}
        navigation
      >
        <SwiperSlide>
          <ReviewCard
            text={`Отличный курс. За время обучения смог закрыть свои некоторые пробелы и подтянуть забытое. Главная ценность курса - ориентированность на практику и опыт преподавателей, которые в доступной форме преподносят знания.`}
            name="Лев Шмелев"
            status="Эксперт информационной безопасности"
          >
            <img src={Reviewer1} alt="" />
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`Курс хорошо структурирован. Важные аспекты изложены доступно и подкреплены интересными практическими примерами от действующих специалистов. После курса остались положительные впечатления.`}
            name="Шевченко Екатерина"
            status="Эксперт информационной безопасности"
          >
            <img src={Reviewer2} alt="" />
          </ReviewCard>
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard
            text={`Считаю данный курс оптимальной точкой входа в мир ИБ, для специалистов любого класса.  Грамотный подбор тренеров и обратная связь, очень сильно повлияли на этот курс, благодаря чему интерес и тяга к изучению, не пропадают даже после его окончания.`}
            name="Коломийцев Владислав"
            status="Эксперт информационной безопасности"
          >
            <img src={Reviewer3} alt="" />
          </ReviewCard>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

const ReviewCard = ({ children, text, name, status }) => {
  return (
    <div className="web-reviews-section__card card">
      {children}
      <p className="wt-text wt-text--400 wt-text--center wt-text--italic wt-margin-bottom-45">
        {text}
      </p>
      <span className="wt-text wt-text--red wt-text--200">{name}</span>
      <span className="wt-text wt-text--gray wt-text--100 wt-text--center">
        {status}
      </span>
    </div>
  )
}

export default ReviewsSection
