import React from "react"
import "./styles.css"
import TsarkaFlag from "../../../images/tsarka-flag.png"

const FaqSection = ({ setIsModalOpen }) => {
  return (
    <section className="asu-faq-section container">
      <div className="row">
        <div className="col-4 col-md-12">
          <h3 className="wt-text wt-text--500 wt-margin-bottom-35">
            Курс включает в себя
          </h3>
          <ul>
            <li className="wt-text wt-text--300">
              Live демонстрацию атак на технологический процесс на реальной
              SCADA системе
            </li>
            <li className="wt-text wt-text--300">
              Использованию NG файрволов для защиты промышленных протоколов
            </li>
            <li className="wt-text wt-text--300">
              Примеры использования систем и методов защиты критической
              инфраструктуры
            </li>
            <li className="wt-text wt-text--300">Неинвазивные методы аудита</li>
          </ul>
        </div>

        <div className="col-8 col-md-12">
          <div className="asu-faq-section__request">
            <div className="asu-faq-section__info">
              <p className="wt-text wt-text--red wt-text--400 wt-margin-bottom-15">
                Набор открыт.
              </p>
              <h3 className="wt-text wt-text--500 wt-margin-bottom-15">
                Безопасность
                <br />
                АСУ ТП
              </h3>
              <p className="wt-text wt-text--200 asu-faq-section__text">
                Форма обучения: онлайн курс.
                <br />
                Периодичность проведения: 3 дня
                <br />
                Продолжительность: 7 академ. часов.
              </p>
              <button
                className="asu-faq-section__btn wt-button wt-width-1-1"
                onClick={() => setIsModalOpen(true)}
              >
                Подать заявку
              </button>
            </div>
            <img
              src={TsarkaFlag}
              alt="Flag"
              className="asu-faq-section__flag"
              width="350px"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqSection

// Live демонстрацию атак на технологический процесс на реальной SCADA системе

// Использованию NG файрволов для защиты промышленных протоколов

// Примеры использования систем и методов защиты критической инфраструктуры

// Неинвазивные методы аудита
