import React from "react"
import "./styles.css"
import ArtImage from "../../../images/bg-art.png"

const ProgramSection = () => {
  return (
    <section id="program" className="iso-program-section container">
      <div className="iso-program-section__head">
        <h2 className="wt-text wt-text--500 wt-margin-bottom-0">
          ПРОГРАММА КУРСА
          <span className="wt-text wt-text--red wt-text--450">
            &nbsp; Набор открыт
          </span>
        </h2>
        <div className="web-program-section__pdf">
          <div className="wt-text wt-text--200 wt-text--red">СКАЧАТЬ PDF</div>
          <div>
            <a
              target="_blank"
              href="https://cert.kz/wp-content/uploads/2022/04/KP_iso_4.pdf"
              className="wt-text wt-text--300"
            >
              КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ
            </a>
            <span className="web-program-section__space">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <a
              target="_blank"
              href="https://cert.kz/wp-content/uploads/2022/04/iso-program_2.pdf"
              className="wt-text wt-text--300"
            >
              ПРОГРАММА
            </a>
          </div>
        </div>
      </div>

      <div className="iso-program-section__modules">
        <div className="iso-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 1</span>
            <span className="wt-text wt-text--300">
              Основы ISO 27001, структура, требуемые документы, как внедрить
              этот стандарт.
            </span>
          </div>
          <span className="iso-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>

        <div className="iso-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 2</span>
            <span className="wt-text wt-text--300">
              Классификация Понимание контекста организации, заинтересованных
              сторон, объём, ответственность руководства, цели ИБ,
              компетентность, осведомлённость.
            </span>
          </div>
          <span className="iso-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>

        <div className="iso-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 3</span>
            <span className="wt-text wt-text--300">
              Риски, управление рисками, оценка рисков ИБ, обработка рисков,
              заявление о применимости.
            </span>
          </div>
          <span className="iso-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>

        <div className="iso-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 4</span>
            <span className="wt-text wt-text--300">
              План обработки риска, внедрение контрольных мероприятий.
            </span>
          </div>
          <span className="iso-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>

        <div className="iso-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 5</span>
            <span className="wt-text wt-text--300">
              Мониторинг и измерение, внутренний аудит, контроль со стороны
              руководства, корректирующие действия.
            </span>
          </div>
          <span className="iso-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>

        <div className="iso-program-section__module">
          <div className="wt-flex-column">
            <span className="wt-text wt-text--300 wt-text--red">Модуль 6</span>
            <span className="wt-text wt-text--300">
              Обзор контролей из Приложения «А».
            </span>
          </div>
          <span className="iso-program-section__time wt-text wt-text--red">
            2 часа
          </span>
        </div>
      </div>

      <img src={ArtImage} alt="art" className="iso-program-section__art" />
    </section>
  )
}

export default ProgramSection
