import React from "react"
import "./styles.css"
import CardImage from "../../../images/card2.png"

const DocumentSection = () => {
  return (
    <section className="web-document-section container">
      <div className="card">
        <img
          src={CardImage}
          alt="card"
          className="web-document-section__card1"
        />
        <img
          src={CardImage}
          alt="card"
          className="web-document-section__card2"
        />
        <img
          src={CardImage}
          alt="card"
          className="web-document-section__card3"
        />
        <div className="web-document-section__content">
          <h2 className="wt-text wt-text--600">Документ об окончании курса:</h2>
          <p>
            Сертификат о прохождении курса
            <br /> «Практические основы пентеста»,
          </p>
          <p>
            Сертификат об успешной защите проекта в рамках курса «Практические
            основы пентеста».
          </p>
        </div>
      </div>
    </section>
  )
}

export default DocumentSection
