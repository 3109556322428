import React from 'react'
import './styles.css'

const WhoSection = () => {
  return (
    <section className="iso-who-section container">
      <div className="row">
        <div className="col-4 col-md-12">
          <div className="iso-who-section__info">
            <div className="iso-who-section__info-title">
              Какова цель курса?
            </div>
            <div className="iso-who-section__info-text">
              Научить слушателей понимать требования стандарта ISO 27001:2013 и как реализовать
              их на практике.
            </div>
          </div>
        </div>
        <div className="col-8 col-md-12">
          <div className="iso-who-section__info">
            <div className="iso-who-section__info-title">
              Для кого этот курс?
            </div>
            <div className="iso-who-section__info-text">
              Курс предназначен для сотрудников подразделений информационной
              безопасности, IT - подразделений, служб внутреннего аудита,
              аналитики, риск-менеджмента, а также всех, чья деятельность
              связана с информационной безопасностью, и кто желает повысить свою
              ценность как специалиста.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoSection
