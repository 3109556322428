import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../sections/Header"
import Main from "../sections/web/Main"
import Information from "../sections/web/Information"
import ValueSection from "../sections/web/Value"
import FaqSection from "../sections/web/Faq"
import DocumentSection from "../sections/web/Document"
import ResultSection from "../sections/web/Result"
import PriceSection from "../sections/web/Price"
import TrainerSection from "../sections/web/Trainer"
import ProgramSection from "../sections/web/Program"
import ReviewsSection from "../sections/web/Reviews"
import AboutSection from "../sections/web/About"
import Footer from "../sections/Footer"
import "tippy.js/dist/tippy.css"
import ReactModal from "react-modal"
import RequestForm from "../components/RequestForm"
import WhoSection from "../sections/web/Who"
import PricinngDep from "../sections/web/PricinngDep"

ReactModal.setAppElement("#___gatsby")

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 20px 60px -2px rgba(27, 33, 58, 0.4)",
    border: "none",
    borderRadius: "3px",
    padding: "30px",
    overflow: "visible",
    width: "360px",
  },
}

const WebPage = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout>
      <SEO title="TSARKA Academy" />
      <Header
        menuItems={[
          {
            id: "https://school.cert.kz/",
            text: "Школа",
            target: "_blank",
          },
          {
            id: "#program",
            text: "Программа",
          },
          {
            id: "#trainer",
            text: "Тренера",
          },
          {
            id: "#about",
            text: "О нас",
          },
          {
            id: "#footer",
            text: "Контакты",
          },
        ]}
      />

      <Main />
      <Information />
      {/*<MissionSection/>*/}
      <ValueSection />

      <WhoSection />
      <FaqSection setIsModalOpen={setIsModalOpen} />
      <DocumentSection />
      <ResultSection />
      <PricinngDep />
      <PriceSection setIsModalOpen={setIsModalOpen} />
      <TrainerSection />
      <ProgramSection />
      <ReviewsSection />
      <AboutSection />

      <Footer />

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <RequestForm price={modalIsOpen} setIsModalOpen={setIsModalOpen} />
      </ReactModal>
    </Layout>
  )
}

export default WebPage
