import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../sections/Header"
import Main from "../sections/asu/Main"
import ValueSection from "../sections/asu/Value"
import WhoSection from "../sections/asu/Who"
import FaqSection from "../sections/asu/Faq"
import DocumentSection from "../sections/asu/Document"
import ResultSection from "../sections/asu/Result"
import PricingSection from "../sections/asu/Pricing"
import TrainerSection from "../sections/asu/Trainer"
import ProgramSection from "../sections/asu/Program"
import ReviewsSection from "../sections/asu/Reviews"
import Footer from "../sections/Footer"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReactModal from "react-modal"
import RequestForm from "../components/RequestForm"
// import { customStyles } from "./pentest"
import { customStyles } from "./pentest"

const AsuPage = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout>
      <SEO title="Безопасность АСУ ТП" />
      <Header
        setIsModalOpen={setIsModalOpen}
        menuItems={[
          {
            id: "#value",
            text: "О курсе",
          },
          {
            id: "#price",
            text: "Стоимость",
          },
          {
            id: "#trainer",
            text: "Тренер",
          },
          {
            id: "#program",
            text: "Программа",
          },
          {
            id: "#reviews",
            text: "Отзывы",
          },
        ]}
      >
        <button
          onClick={() => setIsModalOpen(true)}
          className="wt-button wt-button--small wt-button--no-upper wt-margin-left-15"
        >
          Забронировать место
        </button>
      </Header>

      <Main />
      <ValueSection />
      <WhoSection />
      <FaqSection setIsModalOpen={setIsModalOpen} />
      <DocumentSection />
      <ResultSection />
      <PricingSection setIsModalOpen={setIsModalOpen} />
      <TrainerSection />
      <ProgramSection />
      <ReviewsSection />

      <Footer />

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <RequestForm
          price={modalIsOpen}
          setIsModalOpen={setIsModalOpen}
          course={"Безопасность АСУ ТП"}
        />
      </ReactModal>
    </Layout>
  )
}

export default AsuPage
