import React from "react"
import './styles.css'
import { graphql, useStaticQuery } from "gatsby"
import BgArt from "../../../images/bg-art.png"
import Img from "gatsby-image"

const MissionSection = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "horse.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id='mission' className='web-mission-section container'>
      <img className='web-mission-section__art' src={BgArt} alt="art"/>
      <div className='web-mission-section__image'>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </div>
      <div className='web-mission-section__content'>
        <h2 className='wt-text wt-text--600'>Наша<br/> миссия</h2>
        <p className='wt-text wt-text--400 wt-margin-bottom-45'>
          Для нас, как экспертов информационной безопасности, принципиально
          важны доступность и достоверность источников информации. Мы рады и
          готовы делиться собственным уникальным опытом и знаниями с желающими
          расти и развиваться профессионально.
        </p>
        <h2 className='wt-text wt-text--600'>
          Что такое ЦАРКА<br/>
          <span className='wt-text wt-text--red'>академия?</span>
        </h2>
        <p className='wt-text wt-text--400'>
          Созданный при ЦАРКА учебный центр, осуществляющий подготовку,
          переподготовку и повышение квалификации специалистов по различным
          направлениям информационной безопасности.
        </p>
      </div>
    </section>
  )
}

export default MissionSection
